import {classNames} from '@shopify/css-utilities';
import {useCallback, useEffect, useMemo, useRef, useState} from 'preact/hooks';

import {PAY_AUTH_DOMAIN} from '~/constants/authorize';
import {useI18n} from '~/foundation/I18n/hooks';
import {useMonorail} from '~/foundation/Monorail/hooks';
import {useAuthorizeEventListener} from '~/hooks/useAuthorizeEventListener';
import {useElementEventListener} from '~/hooks/useElementEventListener';
import type {CustomFlowSideEffectEvent} from '~/types/event';
import {postMessage} from '~/utils/postMessage';
import {isoWindow} from '~/utils/window';

import {useInstallments} from '../context/InstallmentsContext';
import {usePaymentTermsMonorail} from '../monorail';
import type {InstallmentsPrequalPageType} from '../types';
import {convertPriceToNumber} from '../utils/price';

import {AnimatedShopLogo} from './AnimatedShopLogo';
import {InstallmentsModalFooter} from './InstallmentsModalFooter';
import {InstallmentsPrequalOverlay} from './InstallmentsPrequalOverlay';
import {LargeSpinner} from './LargeSpinner';
import {PrequalButton} from './PrequalButton';
import {SamplePlanList} from './SamplePlansList';

type ContentState = 'loading' | 'iframe' | 'mainContent';

const RESULT_PATH = '/pay/installments/prequalifications/authorize';

export interface PrequalModalContentProps {
  onClose?: () => void;
}

export const PrequalModalContent = ({onClose}: PrequalModalContentProps) => {
  const {
    checkoutUrl,
    fullPrice: priceWithoutInterest,
    metaType,
    sellerId,
    variantInfo,
  } = useInstallments();
  const sellerIdNumber = useMemo(() => Number(sellerId), [sellerId]);
  const {translate} = useI18n();
  const {trackInstallmentsPrequalPopupPageImpression} =
    usePaymentTermsMonorail();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [nextPage, setNextPage] = useState<InstallmentsPrequalPageType>(
    'prequal_authorize_loaded',
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  const [prequalSideEffectEventReceived, setPrequalSideEffectEventReceived] =
    useState(false);
  const [completedEventReceived, setCompletedEventReceived] = useState(false);
  const [state, setState] = useState<ContentState>('mainContent');
  const [showPrequalOverlay, setShowPrequalOverlay] = useState(false);
  const [iframeActive, setIframeActive] = useState(false);
  const {analyticsData: analyticsTraceId} = useMonorail();

  const authorizeUrl = useMemo(() => {
    const payDomain = PAY_AUTH_DOMAIN.replace('https://', '');
    return `${PAY_AUTH_DOMAIN}${RESULT_PATH}?shopify_domain=${isoWindow.location.hostname}&pay_domain=${payDomain}&analytics_trace_id=${analyticsTraceId}&redirect_source=${isoWindow.location.origin}`;
  }, [analyticsTraceId]);

  const getContentModalHeight = (): number => {
    const modalHeight = 717;
    const padding = 75;
    return Math.min(isoWindow.innerHeight - padding, modalHeight - padding);
  };

  const handleContinueToCheckout = useCallback(async () => {
    if (checkoutUrl) {
      isoWindow.location.assign(checkoutUrl);
      trackInstallmentsPrequalPopupPageImpression({
        pageType: 'prequal_continue_to_checkout_clicked',
        sellerId: sellerIdNumber,
      });
    }
  }, [
    checkoutUrl,
    sellerIdNumber,
    trackInstallmentsPrequalPopupPageImpression,
  ]);

  const openPrequalBuyerFormOverlay = useCallback(() => {
    trackInstallmentsPrequalPopupPageImpression({
      pageType: 'prequal_buyer_form_overlay_loaded',
      sellerId: sellerIdNumber,
    });
    setShowPrequalOverlay(true);
  }, [sellerIdNumber, trackInstallmentsPrequalPopupPageImpression]);

  const {destroy} = useAuthorizeEventListener({
    onClose,
    onContinueToCheckout: handleContinueToCheckout,
    onPrequalError: () => {
      showFeatureIframe();
    },
    onPrequalMissingInformation: () => {
      setState('mainContent');
      openPrequalBuyerFormOverlay();
    },
    onPrequalSuccess: () => {
      showFeatureIframe();
    },
    onPrequalReady: () => {
      postMessage({
        contentWindow: iframeRef.current?.contentWindow,
        event: {
          type: 'createprequal',
          amount: convertPriceToNumber(priceWithoutInterest!),
          currency: 'USD',
          sellerId: sellerIdNumber,
        },
      });
    },
    onResizeIframe: ({height}) => {
      iframeRef.current!.style.height = `${Math.max(getContentModalHeight(), height)}px`;
    },
    source: iframeRef,
  });

  useEffect(() => {
    const iframe = iframeRef.current;
    return () => {
      if (iframe) {
        destroy();
      }
    };
  }, [destroy]);

  const showFeatureIframe = () => {
    setState('iframe');
  };

  const openFeatureIframe = useCallback(() => {
    if (!iframeRef.current) return;
    setState('loading');
    trackInstallmentsPrequalPopupPageImpression({
      pageType: 'prequal_results_page_loading',
      sellerId: sellerIdNumber,
    });
    setIframeActive(true);
  }, [sellerIdNumber, trackInstallmentsPrequalPopupPageImpression]);

  const elementEventListeners = useMemo(() => {
    const handleOverlayClose = () => {
      setShowPrequalOverlay(false);
    };
    const buyerOnboardingSuccess = () => {
      handleOverlayClose();
      openFeatureIframe();
    };
    const closeOverlayAndModal = () => {
      handleOverlayClose();
      onClose?.();
    };
    const modalopened = () => {
      setButtonLoading(false);
    };
    const overlayClose = () => {
      handleOverlayClose();
    };
    return {
      buyerOnboardingSuccess,
      closeOverlayAndModal,
      modalopened,
      overlayClose,
    };
  }, [onClose, openFeatureIframe]);

  useElementEventListener(elementEventListeners);

  const handleLoginCompleted = useCallback(() => {
    setCompletedEventReceived(true);

    // Do not proceed to next steps if the prequal_flow_side_effect event has not been received yet,
    // because the next steps depend on the spiOnboarded value from that event.
    if (!prequalSideEffectEventReceived) {
      setButtonLoading(true);
      return;
    }

    switch (nextPage) {
      case 'prequal_results_page_loaded':
        openFeatureIframe();
        break;
      case 'prequal_buyer_form_overlay_loaded':
        openPrequalBuyerFormOverlay();
        break;
    }
  }, [
    nextPage,
    openFeatureIframe,
    openPrequalBuyerFormOverlay,
    prequalSideEffectEventReceived,
  ]);

  const handlePrequalFlowSideEffect = useCallback(
    ({shopPayInstallmentsOnboarded = false}: CustomFlowSideEffectEvent) => {
      setPrequalSideEffectEventReceived(true);
      setNextPage(
        shopPayInstallmentsOnboarded
          ? 'prequal_results_page_loaded'
          : 'prequal_buyer_form_overlay_loaded',
      );

      // Handle async race condition where completed event was received first
      if (completedEventReceived) {
        setButtonLoading(false);
        handleLoginCompleted();
      }
    },
    [completedEventReceived, handleLoginCompleted],
  );

  const legalCopy = translate('paymentTerms.samplePlansContent.legal', {
    lendersLink: (
      // eslint-disable-next-line @shopify/jsx-no-hardcoded-content
      <a
        href="https://www.affirm.com/lenders"
        target="_blank"
        aria-describedby="shopify-payment-terms-modal-warning-text"
        rel="noreferrer"
        className="text-grayscale-d0 underline hover_text-black focus_text-black active_text-black"
      >
        affirm.com/lenders
      </a>
    ),
    licensesLink: (
      // eslint-disable-next-line @shopify/jsx-no-hardcoded-content
      <a
        className="text-grayscale-d0 underline hover_text-black focus_text-black active_text-black"
        href="https://www.affirm.com/licenses"
        target="_blank"
        aria-describedby="shopify-payment-terms-modal-warning-text"
        rel="noreferrer"
      >
        affirm.com/licenses
      </a>
    ),
  });

  const checkIfYouQualifyButtonMarkup = useMemo(() => {
    const handleCheckIfQualifyButtonClick = (event: Event) => {
      setButtonLoading(true);

      switch (nextPage) {
        case 'prequal_authorize_loaded':
          trackInstallmentsPrequalPopupPageImpression({
            pageType: 'prequal_authorize_loaded',
            sellerId: Number(sellerId),
          });
          break;
        case 'prequal_buyer_form_overlay_loaded':
          event.stopPropagation();
          openPrequalBuyerFormOverlay();
          setButtonLoading(false);
          break;
        case 'prequal_results_page_loaded':
          event.stopPropagation();
          openFeatureIframe();
          setButtonLoading(false);
          break;
      }
    };

    return (
      <>
        {buttonLoading && (
          <div className="flex h-10 w-full items-center justify-center rounded-login-button bg-purple-primary">
            <AnimatedShopLogo />
          </div>
        )}
        <div
          className={classNames('h-10 w-full', buttonLoading && 'hidden')}
          data-testid="check-if-you-qualify-button"
          onClick={handleCheckIfQualifyButtonClick}
        >
          <PrequalButton
            disabled={Boolean(variantInfo && !variantInfo.available)}
            handleLoginCompleted={handleLoginCompleted}
            handlePrequalFlowSideEffect={handlePrequalFlowSideEffect}
            storefrontOrigin={isoWindow.location.origin}
          />
        </div>
      </>
    );
  }, [
    buttonLoading,
    handleLoginCompleted,
    handlePrequalFlowSideEffect,
    nextPage,
    openFeatureIframe,
    openPrequalBuyerFormOverlay,
    sellerId,
    trackInstallmentsPrequalPopupPageImpression,
    variantInfo,
  ]);

  const processingMarkup = (() => {
    return (
      <div
        class="flex animate-fade-in flex-col items-center justify-center p-8 font-system"
        data-testid="shop-modal-content-processing"
      >
        <div
          class="flex h-full flex-col items-center justify-center"
          data-testid="shop-modal-content-processing-loading-container"
        >
          <LargeSpinner />
          <div class="mt-5 flex flex-col items-center justify-center gap-2">
            <p data-testid="processing-label">
              {translate('paymentTerms.prequalContent.processing')}
            </p>
            <p
              class="text-caption text-grayscale-primary-light"
              data-testid="processing-time-label"
            >
              {translate('paymentTerms.prequalContent.processingTime')}
            </p>
          </div>
        </div>
      </div>
    );
  })();

  const showIframe = state === 'iframe';

  return (
    <div data-testid="prequal-modal-content">
      {state === 'loading' && processingMarkup}
      <div
        data-testid="shop-modal-feature-iframe-wrapper"
        class={classNames(!showIframe && 'hidden')}
      >
        <iframe
          data-testid="prequal-modal-iframe"
          className="max-h-[80vh] w-[432px] max-w-full"
          tabIndex={0}
          ref={iframeRef}
          // eslint-disable-next-line @shopify/jsx-no-complex-expressions
          src={iframeActive ? authorizeUrl : ''}
        />
      </div>
      {state === 'mainContent' && (
        <>
          <SamplePlanList />
          {metaType !== 'checkout' && (
            <>
              <div
                data-testid="navigation-buttons"
                className="mt-4 flex flex-col items-center"
              >
                {checkIfYouQualifyButtonMarkup}
              </div>
              <div className="my-1 flex flex-col items-center text-caption font-light leading-normal">
                <p className="text-center font-light">
                  {translate(
                    'paymentTerms.samplePlansContent.informationShared',
                  )}
                </p>
                <p
                  data-testid="check_eligibility"
                  className="text-center font-light"
                >
                  {translate(
                    'paymentTerms.samplePlansContent.checkingEligibility',
                  )}
                </p>
              </div>
            </>
          )}
          <p
            id="eligibility-approval"
            className="text-inherit mb-5 mt-8 text-caption font-light tracking-wider text-grayscale-d0"
          >
            {legalCopy}
          </p>
        </>
      )}
      {!showIframe && <InstallmentsModalFooter />}
      {showPrequalOverlay && <InstallmentsPrequalOverlay />}
    </div>
  );
};
