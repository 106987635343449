import {createContext} from 'preact';
import type {Dispatch, StateUpdater} from 'preact/hooks';
import {useContext} from 'preact/hooks';

import type {Cart} from '~/types/cart';

import type {
  AvailableLoanType,
  InstallmentPlan,
  InstallmentsBannerContent,
  InstallmentsBannerType,
  InstallmentsModalVariant,
  MinIneligibleMessageType,
  SamplePlan,
  Term,
  VariantModalDetails,
} from '../types';

export interface InstallmentsContextType {
  backgroundColor: string;
  bannerContent: InstallmentsBannerContent;
  canShowSamplePlanModalContent: boolean;
  cartDetails?: Cart;
  cartPermalink?: string;
  checkoutUrl: string | null;
  dataLoaded: boolean;
  eligible: boolean;
  financingTermForBanner: Term | null | undefined;
  fullPrice: string;
  hasZeroInterestLoanType: boolean;
  installmentPlans: InstallmentPlan[];
  installmentsBuyerPrequalificationEnabled: boolean;
  isEligibleForPrequalification: boolean;
  isInAdaptiveRangeWithoutZeroInterest: boolean;
  isLegacyBanner: boolean;
  loanTypes: AvailableLoanType[];
  maxPrice: string;
  metaType: InstallmentsBannerType;
  minIneligibleMessageType?: MinIneligibleMessageType;
  minPrice: string;
  modalOpen: boolean;
  modalToken: string;
  modalVariant: InstallmentsModalVariant;
  numberOfPaymentTerms: number;
  pricePerTerm: string;
  samplePlans: SamplePlan[];
  sellerId?: string;
  setModalOpen: Dispatch<StateUpdater<boolean>>;
  variantAvailable: boolean;
  variantId?: number;
  variantInfo?: VariantModalDetails;
}

export const initialContext: InstallmentsContextType = {
  backgroundColor: '#fff',
  bannerContent: 'pay_in_4',
  canShowSamplePlanModalContent: false,
  cartDetails: undefined,
  cartPermalink: undefined,
  checkoutUrl: null,
  dataLoaded: false,
  eligible: false,
  financingTermForBanner: null,
  fullPrice: '',
  hasZeroInterestLoanType: false,
  installmentPlans: [],
  installmentsBuyerPrequalificationEnabled: false,
  isEligibleForPrequalification: false,
  isInAdaptiveRangeWithoutZeroInterest: false,
  isLegacyBanner: false,
  loanTypes: [],
  maxPrice: '$3000',
  metaType: 'product',
  minIneligibleMessageType: undefined,
  minPrice: '$50',
  modalOpen: false,
  modalToken: '',
  modalVariant: 'splitPay',
  numberOfPaymentTerms: 4,
  pricePerTerm: '',
  samplePlans: [],
  sellerId: undefined,
  setModalOpen: () => {
    throw new Error(
      'Invalid attempt to call setModalOpen from outside of context',
    );
  },
  variantAvailable: false,
  variantId: undefined,
  variantInfo: undefined,
};

export const InstallmentsContext =
  createContext<InstallmentsContextType>(initialContext);

export const useInstallments = () => {
  return useContext(InstallmentsContext);
};
