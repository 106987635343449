import {useCallback} from 'preact/hooks';

import {useI18n} from '~/foundation/I18n/hooks';

import {useInstallments} from '../context/InstallmentsContext';
import {usePaymentTermsMonorail} from '../monorail';

import {ContinueToCheckoutButton} from './ContinueToCheckoutButton';
import {InstallmentsModalFooter} from './InstallmentsModalFooter';
import {SamplePlanList} from './SamplePlansList';

export const SamplePlansModalContent = () => {
  const {cartPermalink, metaType, modalToken, variantInfo} = useInstallments();
  const {translate} = useI18n();
  const {trackModalAction} = usePaymentTermsMonorail();

  const legalCopy = translate('paymentTerms.samplePlansContent.legal', {
    lendersLink: (
      // eslint-disable-next-line @shopify/jsx-no-hardcoded-content
      <a
        href="https://www.affirm.com/lenders"
        target="_blank"
        aria-describedby="shopify-payment-terms-modal-warning-text"
        rel="noreferrer"
        className="text-grayscale-d0 underline hover_text-black focus_text-black active_text-black"
      >
        affirm.com/lenders
      </a>
    ),
    licensesLink: (
      // eslint-disable-next-line @shopify/jsx-no-hardcoded-content
      <a
        className="text-grayscale-d0 underline hover_text-black focus_text-black active_text-black"
        href="https://www.affirm.com/licenses"
        target="_blank"
        aria-describedby="shopify-payment-terms-modal-warning-text"
        rel="noreferrer"
      >
        affirm.com/licenses
      </a>
    ),
  });

  const handleContinueToCheckout = useCallback(() => {
    if (variantInfo?.available === false) return;
    trackModalAction({
      modalToken,
      action: 'continue_to_checkout',
      cartPermalink,
    });
  }, [cartPermalink, modalToken, trackModalAction, variantInfo]);

  return (
    <div data-testid="sample-plans-modal-content">
      <SamplePlanList />
      {metaType !== 'checkout' && (
        <>
          <div
            data-testid="navigation-buttons"
            className="mt-4 flex flex-col items-center"
          >
            <div
              data-testid="sample-plans-modal-continue-to-checkout-button"
              className="w-full"
              onClick={handleContinueToCheckout}
            >
              <ContinueToCheckoutButton />
            </div>
          </div>
          <div className="my-1 flex flex-col items-center text-caption font-light leading-normal">
            <p className="text-center font-light">
              {translate('paymentTerms.samplePlansContent.informationShared')}
            </p>
            <p className="text-center font-light">
              {translate('paymentTerms.samplePlansContent.checkingEligibility')}
            </p>
          </div>
        </>
      )}
      <p
        id="eligibility-approval"
        className="text-inherit mb-5 mt-8 text-caption font-light tracking-wider text-grayscale-d0"
      >
        {legalCopy}
      </p>
      <InstallmentsModalFooter />
    </div>
  );
};
