import type {TranslateOptions} from '~/foundation/I18n/types';

import type {InstallmentsContextType} from '../context/InstallmentsContext';
import type {ModalText} from '../types';

import {getSplitPayModalType} from './getModalType';
import {convertPriceToNumber} from './price';

type GetIneligibleMessageKeysProps = Pick<
  InstallmentsContextType,
  'minIneligibleMessageType' | 'numberOfPaymentTerms' | 'fullPrice'
> & {
  priceRange?: {
    minPrice: string;
    maxPrice: string;
  };
};
const getIneligibleMessageKeys = ({
  minIneligibleMessageType,
  numberOfPaymentTerms,
  fullPrice,
  priceRange,
}: GetIneligibleMessageKeysProps): ModalText => {
  const minPriceNumber = priceRange?.minPrice
    ? convertPriceToNumber(priceRange.minPrice)
    : null;
  const priceNumber = fullPrice ? convertPriceToNumber(fullPrice) : null;

  const legalCopyKeys = [
    'paymentTerms.splitPayContents.legal.ineligible',
    'paymentTerms.splitPayContents.legal.caResidentsNotice',
  ];

  if (!minPriceNumber || !priceRange?.maxPrice) {
    return {
      subTitleKey: '',
      legalCopyKeys,
    };
  }

  if (!priceNumber || priceNumber < minPriceNumber) {
    let translationKey = 'paymentTerms.splitPayContents.subtitle.ineligibleMin';

    if (numberOfPaymentTerms === 2 || numberOfPaymentTerms === 1) {
      translationKey =
        'paymentTerms.splitPayContents.subtitle.ineligibleMinOverTime';
    }

    const ineligibleMonthlySubtitleKey =
      'paymentTerms.splitPayContents.subtitle.ineligibleMonthlyPaymentsMin';

    return {
      subTitleKey:
        minIneligibleMessageType === 'monthly'
          ? ineligibleMonthlySubtitleKey
          : translationKey,
      legalCopyKeys,
    };
  }

  const subTitleKey = 'paymentTerms.splitPayContents.subtitle.ineligibleMax';

  return {
    subTitleKey,
    legalCopyKeys,
  };
};

type GetSplitPayModalTextProps = Pick<
  InstallmentsContextType,
  | 'eligible'
  | 'loanTypes'
  | 'minIneligibleMessageType'
  | 'numberOfPaymentTerms'
  | 'fullPrice'
> & {
  priceRange?: {
    minPrice: string;
    maxPrice: string;
  };
};
export const getSplitPayModalText = ({
  eligible,
  fullPrice,
  loanTypes,
  minIneligibleMessageType,
  numberOfPaymentTerms,
  priceRange,
}: GetSplitPayModalTextProps): ModalText => {
  const modalType = getSplitPayModalType({eligible, loanTypes});

  switch (modalType) {
    case 'adaptive': {
      const legalCopyKeysAdaptive = [
        'paymentTerms.splitPayContents.legal.interestAndSplitPay',
        'paymentTerms.splitPayContents.legal.ratesFromApr',
        'paymentTerms.splitPayContents.legal.caResidentsNotice',
      ];

      const subTitleKeyAdaptive =
        'paymentTerms.splitPayContents.subtitle.interestAndSplitPay';

      return {
        subTitleKey: subTitleKeyAdaptive,
        legalCopyKeys: legalCopyKeysAdaptive,
      };
    }

    case 'split_pay': {
      const legalCopyKeysSplitPay = [
        'paymentTerms.splitPayContents.legal.splitPayOnly',
        'paymentTerms.splitPayContents.legal.caResidentsNotice',
      ];

      let translationKeySplitPay =
        'paymentTerms.splitPayContents.subtitle.splitPayOnly';

      if (numberOfPaymentTerms === 2) {
        translationKeySplitPay =
          'paymentTerms.splitPayContents.subtitle.splitPayOnly2';
      } else if (numberOfPaymentTerms === 1) {
        translationKeySplitPay =
          'paymentTerms.splitPayContents.subtitle.splitPayOnly30';
      }

      return {
        subTitleKey: translationKeySplitPay,
        legalCopyKeys: legalCopyKeysSplitPay,
      };
    }

    case 'interest_only': {
      const affirmUrlKey: [string, TranslateOptions] = [
        'paymentTerms.splitPayContents.legal.ratesFromApr',
        {
          link: (
            // eslint-disable-next-line @shopify/jsx-no-hardcoded-content
            <a
              className="hover:text-indigo-700 active:text-indigo-700 focus:text-indigo-700 text-grayscale-d0 underline"
              href="https://www.affirm.com/licenses"
              target="_blank"
              aria-describedby="shopify-payment-terms-modal-warning-text"
              rel="noreferrer"
            >
              affirm.com/lenders
            </a>
          ),
        },
      ];
      const legalCopyKeysInterestOnly = [
        affirmUrlKey,
        'paymentTerms.splitPayContents.legal.caResidentsNotice',
      ];

      const subTitleKeyInterestOnly =
        'paymentTerms.splitPayContents.subtitle.interestOnly';

      return {
        subTitleKey: subTitleKeyInterestOnly,
        legalCopyKeys: legalCopyKeysInterestOnly,
      };
    }

    case 'ineligible':
    default:
      return getIneligibleMessageKeys({
        fullPrice,
        priceRange,
        numberOfPaymentTerms,
        minIneligibleMessageType,
      });
  }
};
